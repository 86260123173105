import styled from 'styled-components';
import { sizes } from '../../Utils/screenUtil';

export const Container = styled.div<{ isLeftbarOpen: boolean }>`
  display: grid;
  overflow: hidden;
  height: 100vh; /* Fallback for browsers that don't support svh */
  height: 100svh; /* Overrides for browsers that do support svh */
  /* Grid areas layout */
  grid-template-areas:
    'nav nav nav'
    'left main right'
    'bottom bottom bottom';

  /* Column widths adjust based on sidebar status */
  grid-template-columns: ${({ isLeftbarOpen }) =>
    isLeftbarOpen ? '350px 1fr 350px' : '75px 1fr 350px'};

  /* Rows should accommodate content dynamically */
  grid-template-rows: auto 1fr auto;

  @media only screen and (max-width: ${sizes.laptopL}) {
    grid-template-columns: ${({ isLeftbarOpen }) =>
      isLeftbarOpen ? '1fr 3fr' : '75px 3fr'};
    grid-template-areas:
      'nav nav nav'
      'left main right'
      'bottom bottom bottom';
  }

  // New media for smaller than tablet screens
  @media only screen and (max-width: ${sizes.tablet}) {
    grid-template-columns: ${({ isLeftbarOpen }) =>
      isLeftbarOpen ? '0 3fr' : '0 3fr'};
    grid-template-areas:
      'nav nav nav'
      'left main right'
      'bottom bottom bottom';
  }
`;
