import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTypewriter } from 'react-simple-typewriter';
import { ILoad } from '../../Models/LoadModel';
import { IUser } from '../../Models/UserModel';
import { Title } from '../../App/Styles/Typography.styled';
import { EMAIL_TEMPLATE } from '../../Other/constants';
import { PortalContext } from '../../Context/Portal/PortalContext';
import { formatDateTime } from '../../Utils/datetimeUtil';
import { AuthenticationContext } from '../../Context/Auth/AuthContext';

type Props = {
  load: ILoad | null;
};

const formatUser = (user: IUser) => {
  return `${user.first_name ? user.first_name : ''} ${
    user.last_name ? user.last_name : ''
  }\nEmail: ${user.email}\n${
    user.phone_number ? 'Cell: ' + user.phone_number + '\n' : ''
  }${
    user.mc_number ? 'MC: ' + user.mc_number + '\n' : ''
  }DOT: ${user.dot_number}\n
  `
}

const getEmailTemplate = (load: ILoad | null, user: IUser | undefined) => {
  if (load === null) return EMAIL_TEMPLATE;
  else
    return `Hello,\n\n\nCould you provide more details for the load below?\nOrigin: ${
      load.origin_city
    }/${load.origin_state}\nDestination: ${load.destination_city}/${
      load.destination_state
    }\nEquipment: ${load.equipment_type}\nPrice: $${
      load.total_price
    }\nRPM: $${load.rate_per_mile}\nPickup: ${formatDateTime(
      load.pickup!!
    )}\n\nMy truck will be empty on ${formatDateTime(load.pickup!!)}\n${
      load.total_price ? 'Is there a possibility to raise the rate?' : 'What rate do you offer?'
    }\n\n${user ? formatUser(user) : ''}`;
};

const EmailDraftModal: React.FC<Props> = ({ load }) => {
  const portalContext = useContext(PortalContext);
  const authContext = useContext(AuthenticationContext);
  const defaultSubject = `Tarmac #MC1234567  - ${load?.origin_city}/${load?.origin_state} to ${load?.destination_city}/${load?.destination_state} - Load Details`;

  const [subject, setSubject] = useState(defaultSubject);
  const [textBody, setTextBody] = useState('');
  const [typingComplete, setTypingComplete] = useState(false);

  const [text] = useTypewriter({
    words: [getEmailTemplate(load, authContext?.tokens?.user)],
    typeSpeed: 5,
    onLoopDone: () => setTypingComplete(true),
  });

  // Use effect to set initial textBody when typing is complete
  useEffect(() => {
    if (typingComplete) {
      setTextBody(getEmailTemplate(load, authContext?.tokens?.user));
    }
  }, [typingComplete, load, authContext]);

  const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(event.target.value);
  };

  const handleTextBodyChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setTextBody(event.target.value);
  };

  return (
    <Container>
      <Title bold={true}>Subject</Title>
      <Input type={'text'} value={subject} onChange={handleSubjectChange} />
      <Title bold={true}>Message</Title>
      <TextAreaContainer>
        <TextArea
          spellCheck={false}
          value={typingComplete ? textBody : text}
          onChange={handleTextBodyChange}
          readOnly={!typingComplete} // Make it editable after typing is complete
        />
      </TextAreaContainer>
      <div style={{ display: 'flex' }}>
        <Button
          onClick={() => portalContext?.setIsOpen(false)}
          href={`mailto:${
            load?.email ? load?.email : 'example@example.com'
          }?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
            textBody
          )}`}
        >
          <Title bold={true}>Send Email</Title>
        </Button>
        <Button onClick={() => portalContext?.setIsOpen(false)}>
          <Title bold={true}>Cancel</Title>
        </Button>
      </div>
    </Container>
  );
};

export default EmailDraftModal;

const Container = styled.div`
  padding: 20px;
  max-height: 85%;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.secondary};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;

  ${Title} {
    word-wrap: break-word;
    overflow: hidden;
  }
`;

const Input = styled.input`
  background-color: ${({ theme }) => theme.primary};
  border: none;
  height: 2.5rem;
  border-radius: 10px;
  color: ${({ theme }) => theme.text};
  padding: 0.5rem;

  &:focus {
    outline: 0;
  }
`;

const Button = styled.a`
  width: 100%;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.title};
  transition: ${({ theme }) => theme.transition};
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 10px;
  cursor: pointer;
  opacity: 0.8;

  ${Title} {
    color: ${({ theme }) => theme.primary};
  }

  // Target only the last Button component
  &:last-of-type {
    margin-left: 10px;
    background-color: ${({ theme }) => theme.redBackground};
    transition: ${({ theme }) => theme.transition};
  }

  &:hover {
    opacity: 1;
  }
`;

const TextAreaContainer = styled.div`
  position: relative;
  width: 100%;
`;

const TextArea = styled.textarea`
  overflow: auto;
  resize: none;
  min-width: 500px;
  min-height: 350px;
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.text};
  border: none;
  padding: 0.5rem;
  border-radius: 10px;
  z-index: 1;

  &:focus {
    outline: 0;
  }

  @media only screen and (max-width: 600px) {
    /* Styles for screens less than 600 pixels */
    min-width: 300px;
    max-width: 300px;
  }
`;
