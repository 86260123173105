import { useContext, MouseEvent, useState, useRef, useEffect } from 'react';

import { Paragraph as OriginalParagraph } from '../../App/Styles/Typography.styled';
import loadboardPng from '../../Assets/123loadboard.png';
import truckStopIco from '../../Assets/truckStop.png';
import { PluginContext } from '../../Context/Plugin/PluginContext';
import { Popup } from '../../Components/Popup/Popup';
import useChatApi from '../../Hooks/useApi/useChatApi';
import styled from 'styled-components';
import {
  OfflineLamp,
  OnlineLamp,
  PluginContainer,
  ResponsiveImage,
} from '../../Layouts/Rightbar/RightbarLayout.styled';
import { FlexColumn } from '../../App/Styles/Container.styled';
import { get123LoadboardLoginURL } from '../../Layouts/Rightbar/RightbarLayout';
import { sizes } from '../../Utils/screenUtil';
import { HiddenButton } from '../../App/Styles/Button.styled';

export const ProvidersDropdown = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  function handleProviderClick() {
    setIsOpen((prevVal) => !prevVal);
  }

  const pluginContext = useContext(PluginContext);
  const { loginTruckstop } = useChatApi();
  const [enablePopup, setEnablePopup] = useState<boolean>(false);

  const handleTruckstopSubmit = async (data: string) => {
    // Send Integration ID
    const res = await loginTruckstop(data);

    if (res !== null && res !== undefined) {
      if (res.success) pluginContext!.setTruckstopPlugin(true);
    }
  };

  // Turn truckstop on and off
  const toggleTruckstop = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    pluginContext!.setTruckstopPlugin(!pluginContext!.truckstopPlugin);
  };

  // Close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false); // Close the dropdown
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside as any);
    } else {
      document.removeEventListener('mousedown', handleClickOutside as any);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside as any);
    };
  }, [isOpen]);

  return (
    <ProviderDropdownContainer ref={dropdownRef}>
      <LinkContainer style={{ color: 'white' }} onClick={handleProviderClick}>
        <Paragraph>PROVIDERS</Paragraph>
      </LinkContainer>
      <ModalDiv isOpen={isOpen}>
        {enablePopup ? (
          <Popup
            title="Enter Your Integration ID"
            subtitle="For more information go to https://truckstop.com"
            handleExit={() => setEnablePopup(false)}
            handleSubmit={handleTruckstopSubmit}
            hasInput={true}
          />
        ) : (
          <></>
        )}
        <AddPluginButtonDropdown>
          <ParagraphBold20Dropdown>Add Provider</ParagraphBold20Dropdown>
        </AddPluginButtonDropdown>
        <FlexColumnProviderContainerDropdown justify="">
          <PluginContainerButtonDropdown
            onClick={() => setEnablePopup(true)}
            onContextMenu={toggleTruckstop}
            enabled={true}
          >
            <ResponsiveImage src={truckStopIco} width="2rem" height="2rem" />
            <FlexColumn
              justify="center"
              gap="0.1rem"
              alignItems="center"
            ></FlexColumn>
            {pluginContext!.truckstopPlugin ? <OnlineLamp /> : <OfflineLamp />}
          </PluginContainerButtonDropdown>
          <PluginContainerRightbarDropdown
            to={get123LoadboardLoginURL()}
            enabled={true}
            hasBorder
          >
            <ResponsiveImage src={loadboardPng} width="2rem" height="2rem" />
            <FlexColumn
              justify="center"
              gap="0.1rem"
              alignItems="center"
            ></FlexColumn>
            {pluginContext!.LB123Plugin ? <OnlineLamp /> : <OfflineLamp />}
          </PluginContainerRightbarDropdown>
        </FlexColumnProviderContainerDropdown>
      </ModalDiv>
    </ProviderDropdownContainer>
  );
};

const ProviderDropdownContainer = styled.div`
  position: relative;
  display: none;
  margin-left: 20px;
  @media only screen and (max-width: ${sizes.tablet}) {
    display: block;
  }
`;

const Paragraph = styled(OriginalParagraph)`
  font-size: 14px;
  font-weight: 900;
  color: ${(props) => props.theme.alternativeWhite};
`;

const LinkContainer = styled.a<{
  isDisplayed?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &:hover {
    opacity: 0.7;
  }
  @media only screen and (max-width: ${sizes.tablet}) {
    display: ${({ isDisplayed }) => (!isDisplayed ? 'block' : 'none')};
  }
`;

const ParagraphBold20Dropdown = styled(Paragraph)`
  font-size: 20px;
  font-weight: 500;
  @media (max-width: 1439px) {
    font-size: 16px;
  }
  @media (min-width: 1800px) {
    font-size: 1.3rem !important;
  }
  @media only screen and (min-width: 2240px) {
    /* Styles for screens more than 2240px pixels */
    font-size: 1.6rem !important;
    font-weight: bold;
  }
`;

export const AddPluginButtonDropdown = styled.div`
  display: flex;
  padding: 0.5rem 0.2rem;
  border-radius: 2px 2px 0 0;
  justify-content: center;
  background-color: ${({ theme }) => theme.alternativeWhite};

  ${Paragraph} {
    color: ${({ theme }) => theme.primary};
    font-weight: bold;
  }
`;

const FlexColumnProviderContainerDropdown = styled(FlexColumn)`
  position: relative;
  display: flex;
  background-color: ${({ theme }) => theme.sideMenuAccent};
  border-radius: 0 0 2px 2px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid ${(props) => `${props.theme.detailsText}30`};
  flex-grow: 1; /* Make this container expand to fill available space */
  /* Hide scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    display: none; /* WebKit-based browsers */
  }
`;

export const PluginContainerButtonDropdown = styled(HiddenButton)<{
  enabled: boolean;
}>`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: ${({ theme }) => theme.transition};
  padding: 1rem 0.5rem;
  opacity: ${(props) => (props.enabled ? '1' : '0.5')};
  cursor: ${(props) => (props.enabled ? 'pointer' : 'default')};
  border-bottom: 1px solid rgba(178, 165, 165, 0.18);
  border-radius: 0;
  width: 100%;

  &:hover {
    opacity: 0.5;
  }
`;

export const PluginContainerRightbarDropdown = styled(PluginContainer)`
  height: 50px;
`;

const ModalDiv = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 2;
  position: absolute;
  bottom: -145px;
  left: -20px;
  width: 120px;
  max-height: 200px;
  background-color: none;
`;
